<template>
  <b-card no-body class="action-buttons">
    <b-row class="mb-1 mx-1">

      <b-col offset-md="6" offset-xl="8" md="6" xl="4" class="mt-2">
        <!-- basic -->
        <b-form-group>
          <b-input-group>

            <b-form-input v-model.trim="search" placeholder="Search for countries" />

            <b-input-group-append>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon rounded-circle ml-1"
                @click="countriesRefresh"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>

          </b-input-group>

        </b-form-group>

        
      </b-col>
    </b-row>

    <b-table
      show-empty
      empty-text="No records found from your search"
      empty-filtered-text="No records found from your search"
      hover
      striped
      :busy.sync="isBusy"
      :current-page="currentPage"
      :per-page="perPage"
      responsive
      :items="countries"
      :fields="fields"
      ref="countriesTable"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(active)="data">
          <b-button
            v-if="data.item.active"
            variant="outline-success"
            class="text-capitalize"
            pill
            size="sm"
          >
            Active
          </b-button>

          <b-button
            v-else
            variant="outline-danger"
            class="text-capitalize"
            pill
            size="sm"
          >
            Suspended
          </b-button>
        </template>

      <template #cell(actions)="row">
        <b-button
          v-if="row.item.active"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="row.item.users_count != 0 || row.item.contacts_count != 0"
          variant="danger"
          size="sm"
          v-b-modal.suspend-country
          @click="selectCountryId(row.item.ID)"
        >
          Suspend
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          v-b-modal.activate-country
          @click="selectCountryId(row.item.ID)"
        >
          Activate
        </b-button>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      size="lg"
    ></b-pagination>

    <activate-country
      :id="countryId"
      @country-submited="countriesRefresh"
      :invalid="true"
    />
    <suspend-country
      :id="countryId"
      @country-submited="countriesRefresh"
    />
  </b-card>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
  VBModal,
} from "bootstrap-vue";
import ActivateCountry from "./components/ActivateCountry.vue";
import SuspendCountry from "./components/SuspendCountry.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    ActivateCountry,
    SuspendCountry,
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "title",
          label: "TITLE",
        },
        {
          key: "iso",
          label: "ISO",
        },
        {
          key: "code",
          label: "CODE",
        },
        {
          key: "active",
          label: "STATUS",
        },
        {
          key: "users_count",
          label: "USERS",
        },
        {
          key: "contacts_count",
          label: "CONTACTS",
        },
        {
          key: "actions",
          label: "ACTION",
        },
      ],
      search: "",
      countryId:false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
    };
  },
  methods: {
    countries(ctx, callback) {
      let params
      if (this.search) {
        params = "?page=" + ctx.currentPage + "&search=" + this.search;
      } else {
        params = "?page=" + ctx.currentPage;
      }
      this.$http
        .get("/countries/" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
         
          this.isBusy = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    countriesRefresh() {
      this.countryId = false;
      this.$refs.countriesTable.refresh();
    },
    selectCountryId(id) {
      this.countryId = id;
    },
  },
};
</script>